import Api from '@/api';
import { pickBy } from 'lodash-es';
import {userItem, userProfilePayload, userProfile, userList} from './selectors';
import Vue from 'vue';

class User extends Api {
  current() {
    return this.rest(
      '/user/current.json',
    ).then(userItem);
  }
  profile() {
    return this.rest(
      '/user/profile-get.json',
    ).then(userProfile);
  }
  fetchList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/user/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(userList);
  }

  edit({ values }) {
    return this.rest(
      '/user/profile-edit.json', {
        method: 'POST',
        data: userProfilePayload(values),
      }
    ).then(userProfile);
  }
  create({ values, captcha, sid }) {
    return this.rest(
      '/user/profile-create.json', {
        method: 'POST',
        data: {
          ...userProfilePayload(values),
          captcha,
          captchaSid: sid,
        },
      }
    ).then(userProfile);
  }
  login({ login, password, portal, redirect }) {
    return this.rest(
      '/user/login.json', {
        method: 'POST',
        data: { login, password, portal, redirect },
      }
    ).then(userItem);
  }
  forgot({ email, captcha, sid }) {
    return this.rest(
      '/user/forgot-password.json', {
        method: 'POST',
        data: { email, captcha, captchaSid: sid },
      }
    ).then((res) => {
      if (email && res.message) Vue.notify({
        type: 'success',
        title: 'Успех',
        text: res.message,
        duration: 10000,
      });
      return res;
    });
  }
  changePassword({ word, login, password, confirmation, captcha, sid }) {
    return this.rest(
      '/user/change-password.json', {
        method: 'POST',
        data: {
          item: {
            login: login,
            checkWord: word,
            password: password,
            confirmPassword: confirmation,
          },
          word,
          captcha,
          captchaSid: sid,
        },
      }
    ).then((res) => {
      if (word && res.message) Vue.notify({
        type: 'success',
        title: 'Успех',
        text: res.message,
        duration: 10000,
      });
      return res;
    });
  }
  editPassword({ currentPassword, newPassword, newPasswordDuplicate }) {
    return this.rest(
      '/user/edit-password.json', {
        method: 'POST',
        data: {
          currentPassword,
          newPassword,
          newPasswordDuplicate,
        },
      }
    )
  }
  logout() {
    return this.rest(
      '/user/logout.json', {
        params: {
          logout: 'yes',
        }
      }
    ).then(userItem);
  }
  item({ inn }) {
    return this.rest(
      '/user/item.json', {
        method: 'POST',
        data: { inn }
      }
    ).then(userItem);
  }
  setRole({ inspect, pkosmr }) {
    return this.rest(
      '/user/set-role.json', {
        method: 'POST',
        data: {
          roles: pickBy({
            inspectRole: inspect,
            pkoSmrRole: pkosmr,
          }, Boolean)
        }
      }
    );
  }
  setAccount({ id }) {
    return this.rest(
      '/user/set-account.json', {
        method: 'POST',
        data: { id }
      }
    );
  }
  empty() {
    return this.rest(
      '/user/profile-create.json',
    )
    .then(userProfile)
    .then((result) => {
      result['values']['UF_USER_TYPE']['values'] = ['6'];
      return result;
    });
  }
}

export default new User();

