<template>
  <VDialog max-width="600" v-model="show" :persistent="!canClose" fullscreen>
    <VCard class="d-flex flex-column justify-center align-items-center">
      <VCardTitle class="text-center justify-center">Вы привязаны к нескольким аккаунтам.<br>Выберете аккаунт, который</VCardTitle>
      <VCardText class="text-center">
        <VMenu bottom offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <VBtn color="primary" v-bind="attrs" v-on="on" depressed>Выберите аккаунт</VBtn>
          </template>
          <UserChoseAccount @setAccount:success="() => this.$router.go(this.$router.currentRoute)"/>
        </VMenu>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import UserChoseAccount from '@/views/user/UserChoseAccount/UserChoseAccount.vue';
export default {
  name: 'UserChoseDialog',
  components: { UserChoseAccount },
  props: {
    value: { type: Boolean, default: false },
    canClose: { type: Boolean, default: false }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
}
</script>

<style module lang="scss">
.root {
  max-width: 400px;
  margin: auto;
}
.nav {
  margin: auto;
  max-width: 400px;
}
.inner {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  font-size: 14px;
}
.label {
  font-weight: 500;
  margin-bottom: 8px;
}
.control {
  text-align: center;
  font-size: 12px;
}
.link {
  color: #f57f29;
  text-decoration: underline;
}
</style>
