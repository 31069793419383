import Api from '@/api';
import {accountApplicationList, accountApplicationDetail, accountApplicationTimeLine} from './selectors';

class AccountApplication extends Api {
  getAccountApplicationDetail({ id }) {
    return this.rest('/account-application/get.json', {
      method: 'POST',
      data: { id },
    }).then(accountApplicationDetail);
  }

  getAccountApplicationList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/account-application/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(accountApplicationList);
  }

  fetchTimeline({ id }) {
    return this.rest('/history/list.json', {
      method: 'POST',
      data: {
        filter: {
          entityId: id,
          entityType: 'ACCOUNT_APPLICATION',
          eventType: 'APPROVING',
        }
      }
    }).then(accountApplicationTimeLine);
  }

  createAccountApplication({ type }) {
    return this.rest('/account-application/create.json', {
      method: 'POST',
      data: {
        item: { type }
      }
    }).then(accountApplicationDetail);
  }

  updateAccountApplication({ id, fields }) {
    return this.rest('/account-application/edit.json', {
      method: 'POST',
      data: {
        item: {
          id,
          ...fields,
        },
      }
    }).then(accountApplicationDetail);
  }

  applyActionAccountApplication({ id, actionCode, comment = '', noLogger = false }) {
    return this.rest('/account-application/apply-action', {
      method: 'POST',
      data: {
        id, actionCode, comment
      }
    }, 30000, noLogger)
  }

  sendInviteToAccount({ id }) {
    return this.rest('/account-application/send-invite-to-account', {
      method: 'POST',
      data: {
        appId: id,
      }
    })
  }

  deleteAccountApplication({ id }) {
    return this.rest('/account-application/delete.json', {
      method: 'POST',
      data: { id },
    });
  }
}

export default new AccountApplication();







