import {get, map, toString} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const accountUsersItem = (item) => {
  return {
    id: get(item, 'id'),
    accountId: get(item, 'accountId'),
    companyName: get(item, 'companyName'),
    status: {
      code: toString(get(item, 'status.code')),
      title: toString(get(item, 'status.title')),
    },
    user: {
      id: get(item, 'user.id'),
      name: toString(get(item, 'user.name')),
      lastName: toString(get(item, 'user.lastName')),
      secondName: toString(get(item, 'user.secondName')),
      fio: shortName(get(item, 'user')),
      email: toString(get(item, 'user.email')),
      phone: toString(get(item, 'user.phone')),
      passportNumber: toString(get(item, 'user.passportNumber')),
      passportDate: toString(get(item, 'user.passportDate')),
      position: toString(get(item, 'user.position')),
      isActive: get(item, 'user.isActive'),
      dateCreate: get(item, 'user.dateCreate'),
      dateConfirm: get(item, 'user.dateConfirm'),
    },
    roles: map(get(item, 'roles'), (role) => ({
      id: get(role, 'id'),
      name: toString(get(role, 'name')),
    })),
  }
}

export const accountUsersEmployee = (item) => {
  return   {
    id: get(item, 'id'),
    dateCreate: toString(get(item, 'dateCreate')),
    name: toString(get(item, 'name')),
    secondName: toString(get(item, 'secondName')),
    lastName: toString(get(item, 'lastName')),
    fio: shortName(item),
    position: toString(get(item, 'position')),
    dateBirth: toString(get(item, 'dateBirth')),
    phone: toString(get(item, 'phone')),
    passportNumber: toString(get(item, 'passportNumber')),
    passportDate: toString(get(item, 'passportDate')),
    email: toString(get(item, 'email')),
  }
}

export const accountUsersDetail = (result) => {
  return {
    ...accountUsersItem(result),
  }
};

export const accountUsersList = (result) => {
  return {
    navCount: get(result, 'navCount'),
    navPage: get(result, 'navPage'),
    navPageCount: get(result, 'navPageCount'),
    navPageSize: get(result, 'navPageSize'),
    items: map(get(result, 'items'), (item) => accountUsersItem(item)),
  }
};

export const accountUsersRoles = (result) => {
  return map(result, (section) => ({
    sectionName: toString(get(section, 'sectionName')),
    roles: map(get(section, 'roles'), (role) => ({
      id: get(role, 'id'),
      name: toString(get(role, 'name')),
    }))
  }))
};

export const accountUsersEmployees = (result) => {
  return {
    navCount: get(result, 'navCount'),
    navPage: get(result, 'navPage'),
    navPageCount: get(result, 'navPageCount'),
    navPageSize: get(result, 'navPageSize'),
    items: map(get(result, 'items'), (item) => accountUsersEmployee(item)),
  }
};
