import {get, merge, omit, toNumber, toString} from 'lodash-es';

export default [
	{
		path: '/account-users/',
		name: 'account-users/AccountUsersView',
		meta: { title: 'Пользователи подрядной организации' },
		props: route => ({
			filter: merge(omit(get(route, 'query'), ['page', 'size'])),
			page: toNumber(get(route, 'query.page', 1)),
			size: toNumber(get(route, 'query.size', 20)),
			tab: toString(get(route, 'query.tab', 'users-organization')),
		}),
		component: () => import('@/views/account-users/AccountUsersView/AccountUsersView'),
	},
	{
		path: '/account-users/application/:application',
		name: 'account-users/AccountUsersApplicationView',
		props: route => ({
			id: toString(get(route, 'params.application')),
		}),
		meta: { title: 'Информация о пользователе' },
		component: () => import('@/views/account-users/AccountUsersApplicationView/AccountUsersApplicationView'),
	},
	{
		path: '/account-users/create',
		name: 'account-users/AccountUsersCreateView',
		props: route => ({
			fromDatabase: !!get(route, 'query.fromDatabase'),
			filter: merge(omit(get(route, 'query'), ['page', 'size', 'fromDatabase'])),
			page: toNumber(get(route, 'query.page', 1)),
			size: toNumber(get(route, 'query.size', 20)),
		}),
		meta: { title: 'Создать нового пользователя' },
		component: () => import('@/views/account-users/AccountUsersCreateView/AccountUsersCreateView'),
	},
	{
		path: '/account-users/:catchAll(.*)',
		redirect: { name: 'user/ProfileView' }
	},
];
