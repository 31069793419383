import api from './api';
import {includes, isArray, some} from 'lodash-es';
const START = 'START';
const END = 'END';

const SET_DETAIL = 'SET_DETAIL';
const SET_LIST = 'SET_LIST';
const SET_INVITE_LIST = 'SET_INVITE_LIST';
const SET_ROLES = 'SET_ROLES';
const SET_EMPLOYEES = 'SET_EMPLOYEES';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchItem: false,
      fetchList: false,
      fetchInviteList: false,
      createItem: false,
      updateItem: false,
      deleteItem: false,
      fetchRoles: false,
      fetchEmployees: false,
      addEmployees: false,
    },
    detail: {},
    roles: {},
    employee: {},
    list: {
      count: 0,
      items: [],
    },
    inviteList: {
      count: 0,
      items: [],
    },
  },
  getters: {
    detail: (state) => {
      return state.detail;
    },
    list: (state) => {
      return state.list;
    },
    inviteList: (state) => {
      return state.inviteList;
    },
    roles: (state) => {
      return state.roles;
    },
    employee: (state) => {
      return state.employee;
    },
    hasStatus(state) {
      return (status) => {
        return isArray(status) ? status.includes(state.detail?.status?.code) : status === state.detail?.status?.code
      };
    },
    pending: (state) => {
      return includes(state.pending, true);
    },
    loading: (state) => (keys) => {
      return some(keys, (key) => state.pending[key])
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_DETAIL]: (state, detail) => {
      state.detail = detail;
    },
    [SET_LIST]: (state, list) => {
      state.list = list;
    },
    [SET_INVITE_LIST]: (state, inviteList) => {
      state.inviteList = inviteList;
    },
    [SET_ROLES]: (state, roles) => {
      state.roles = roles;
    },
    [SET_EMPLOYEES]: (state, employee) => {
      state.employee = employee;
    },
  },
  actions: {
    getAccountUsersDetail: ({ commit }, { id } = {}) => {
      commit(START, 'fetchItem');
      if (!id) {
        commit(SET_DETAIL, {});
        commit(END, 'fetchItem');
        return {}
      }
      return api.getAccountUsersDetail({ id }).then(detail => {
        commit(SET_DETAIL, detail);
        commit(END, 'fetchItem');
        return detail;
      }).catch(() => {
        commit(END, 'fetchItem');
      });
    },
    getAccountUsersList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchList');
      return api.getAccountUsersList({ page, size, filter }).then(list => {
        commit(SET_LIST, list);
        commit(END, 'fetchList');
        return list;
      }).catch(() => {
        commit(END, 'fetchList');
      });
    },
    getAccountInviteList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchInviteList');
      return api.getAccountInviteList({ page, size, filter }).then(list => {
        commit(SET_INVITE_LIST, list);
        commit(END, 'fetchInviteList');
        return list;
      }).catch(() => {
        commit(END, 'fetchInviteList');
      });
    },
    getAccountUsersRoles: ({ commit }) => {
      commit(START, 'fetchRoles');
      return api.getAccountUsersRoles().then(roles => {
        commit(SET_ROLES, roles);
        commit(END, 'fetchRoles');
        return roles;
      }).catch(() => {
        commit(END, 'fetchRoles');
      });
    },
    getAccountUsersEmployees: ({ commit }, { accountId, filter, page, size }) => {
      commit(START, 'fetchEmployees');
      return api.getAccountUsersEmployees({ accountId, filter, page, size }).then(employee => {
        commit(SET_EMPLOYEES, employee);
        commit(END, 'fetchEmployees');
        return employee;
      }).catch(() => {
        commit(END, 'fetchEmployees');
      });
    },
    addAccountUsersEmployees: ({ commit }, { id, accountId }) => {
      commit(START, 'addEmployees');
      return api.addAccountUsersEmployees({ id, accountId }).then(employee => {
        commit(END, 'addEmployees');
        return employee;
      }).catch(() => {
        commit(END, 'addEmployees');
      });
    },
    createUsersAccount: ({ commit }, { accountId, fields } ) => {
      commit(START, 'createItem');
      return api.createAccountUsers({ accountId, fields }).then(detail => {
        commit(SET_DETAIL, detail);
        commit(END, 'createItem');
        return detail;
      }).catch(() => {
        commit(END, 'createItem');
      });
    },
    updateAccountUsers: ({ commit }, { id, fields }) => {
      commit(START, 'updateItem');
      return api.updateAccountUsers({ id, fields }).then((result) => {
        commit(END, 'updateItem');
        return result;
      }).catch(() => {
        commit(END, 'updateItem');
      });
    },

    inviteDecision: ({ commit }, { userId, accountId, decision }) => {
      commit(START, 'inviteDecision');
      return api.inviteDecision({ userId, accountId, decision }).then((result) => {
        commit(END, 'inviteDecision');
        return result;
      }).catch(() => {
        commit(END, 'inviteDecision');
      });
    },

    deleteAccountUsers: ({ commit }, { id }) => {
      commit(START, 'deleteItem');
      return api.deleteAccountUsers({ id }).then((result) => {
        commit(END, 'deleteItem');
        return result;
      }).catch(() => {
        commit(END, 'deleteItem');
      });
    },
  }
}
